<template>
  <article>
    Используя наш сайт вы даете согласие на использование файлов cookie на вашем устройстве в соответствии с нашей Политикой использования cookie. Если собранная информация содержит персональные данные, мы будем обрабатывать ее в соответствии с нашей Политикой конфиденциальности и Условиями использования сервиса.
  </article>
</template>

<script>
export default {
  
}
</script>

<style>

</style>