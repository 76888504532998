const getters = {
    isAuthenticated: (state) => !!state.userId,
    productQuantity: state => productId => {
        const item = state.cart.find(i => i.id === productId);
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    },
    cartProductQuanity: state => {
        let cartCnt = 0;
        if (state.cart) {
            state.cart.forEach(item => {
                cartCnt += item.quantity;
            })
        }
        return cartCnt;
    },
    cartProductIds: state => {
        let ids = '';
        if (state.cart) {
            state.cart.forEach(item => {
                ids += item.id + ',';
            })
            ids = ids.replace(/\,$/, "");
        }
        return ids;
    },
    wishlistProductIds: state => {
        let ids = '';
        if (state.wishlist) {
            state.wishlist.forEach(item => {
                ids += item.id + ',';
            })
            ids = ids.replace(/\,$/, "");
        }
        return ids;
    },
    productInWishlist: state => productId => {
        const item = state.wishlist.find(i => i.id === productId);
        if (item) {
            return 1;
        } else {
            return 0;
        }
    },
    isSetFilters: state => {
        let filters_isset = false;
        if (state.choosen_filters) {
            if (typeof state.choosen_filters === 'object') {
                filters_isset = Object.keys(state.choosen_filters).length > 0 ? true : false;
            } else {
                filters_isset = state.choosen_filters.length > 0 ? true : false;
            }
        }
        //return filters_isset;
        return false;
    },
    GET_INNER_RAZDELS: state => {
        let inner_razdels = [];
        if (state.razdelsList) {
            state.razdelsList.forEach((item) => {
                if (item.in_list === 0) {
                    inner_razdels.push(item);
                }
            });
        }
        inner_razdels = inner_razdels ? JSON.parse(JSON.stringify(inner_razdels)) : {};
        return inner_razdels;
    },
    GET_CURRENT_RAZDEL_NAME: state => {
        let currentRazdelId = Number(state.currentRazdelId) > 0 ? state.currentRazdelId : state.defaultRazdelId;
        let currentRazdelName = '';
        if (state.razdelsList) {
            state.razdelsList.forEach((item) => {
                if (item.id === currentRazdelId) {
                    currentRazdelName = item.name;
                }
            });
        }
        return currentRazdelName;
    },
    GET_MAIN_RAZDELS: state => {
        let main_razdels = [];
        if (state.razdelsList.length) {
            state.razdelsList.forEach((item) => {
                if (item !== null && item.in_list === 1) {
                    main_razdels.push(item);
                }
            });
        }
        main_razdels = main_razdels ? JSON.parse(JSON.stringify(main_razdels)) : {};
        return main_razdels;
    },
    GET_MAIN_RAZDEL_INFO: state => {
        let info = [];
        let currentRazdelId = Number(state.currentRazdelId) > 0 ? state.currentRazdelId : state.defaultRazdelId;
        if (state.razdelsList.length) {
            state.razdelsList.forEach((item) => {
                if (item !== null && item.id === currentRazdelId) {
                    info = item;
                }
            });
        }
        return info;
    },
    GET_MAIN_RAZDEL_BY_ALIAS: state => alias => {
        let razdelId = 0;
        if (state.razdelsList.length) {
            state.razdelsList.forEach((item) => {
                if (item !== null && item.alias === alias) {
                    razdelId = item.id;
                }
            });
        }
        return razdelId;
    }
    
};

export default getters;