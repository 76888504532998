import Loader from '@/views/components/UI/Loader.vue';
import MySvg from '@/views/components/UI/MySvg.vue';
import MyDialog from '@/views/components/UI/MyDialog.vue';
import MyButton from '@/views/components/UI/MyButton.vue';

export default [
    Loader,
    MySvg,
    MyDialog,
    MyButton
]