import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

//Ленивая загрузка Views
//Каждый из этих компонентов будет загружен только тогда, когда пользователь впервые запросит указанный маршрут
const MainPage = () => import('@/views/pages/MainPage');
const CatalogPage = () => import('@/views/pages/CatalogPage');
const CategoriesPage = () => import('@/views/pages/CategoriesPage');
const ProductPage = () => import('@/views/pages/ProductPage');
const StoriesPage = () => import('@/views/pages/StoriesPage');
const CartPage = () => import('@/views/pages/CartPage');
const LkPage = () => import('@/views/pages/LkPage');
const LkOrdersPage = () => import('@/views/pages/LkOrdersPage');
const LkUserPage = () => import('@/views/pages/LkUserPage');
const LkWishlistPage = () => import('@/views/pages/LkWishlistPage');
const InfoPage = () => import('@/views/pages/InfoPage');
const StoreInfoPage = () => import('@/views/pages/StoreInfoPage');
const AboutPage = () => import('@/views/pages/AboutPage');

const PrivacyPage = () => import('@/views/pages/PrivacyPage');
const ContactsPage = () => import('@/views/pages/ContactsPage');
const DeliveryPage = () => import('@/views/pages/DeliveryPage');
const NotFoundPage = () => import('@/views/pages/NotFoundPage');

const routes = [
  {
    path: "/",
    name: "home",
    component: MainPage,
    /*
    meta: {
      title: 'It shop tg - это магазин в телеграме и мощный инструмент для автоматизации и увеличения продаж',
      metaTags: [
        {
          name: 'description',
          content: 'Подключи магазин в телеграмме и перестань терять клиентов из канала в мессенджере. Веб-приложение в телеграме, которое конвертирует ваших подписчиков в клиентов.'
        },
        {
          property: 'og:description',
          content: 'Подключи магазин в телеграмме и перестань терять клиентов из канала в мессенджере. Веб-приложение в телеграме, которое конвертирует ваших подписчиков в клиентов.'
        }
      ]
    }
    */
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesPage,
  },
  {
    path: "/catalog/:currentRazdelAlias",
    name: "catalog",
    component: CatalogPage,
  },
  {
    path: "/catalog/:currentRazdelAlias/:productAlias",
    name: "product",
    component: ProductPage,
  },
  {
    path: "/stories",
    name: "stories",
    component: StoriesPage,
    props: (route) => ({
      storyId: route.query.story_id,
    }),
  },
  {
    path: "/cart",
    name: "cart",
    component: CartPage,
  },
  {
    path: "/lk",
    component: LkPage,
    children: [
      {
        path: "",
        component: LkPage,
        redirect: { name: "lk_orders" },
      },
      {
        path: "orders",
        name: "lk_orders",
        component: LkOrdersPage,
      },
      {
        path: "profile",
        name: "lk_user",
        component: LkUserPage,
      },
      {
        path: "wishlist",
        name: "lk_wishlist",
        component: LkWishlistPage,
      },
    ],
  },
  {
    path: "/info",
    component: InfoPage,
    children: [
      {
        path: "",
        name: "info",
        component: StoreInfoPage,
      },
      {
        path: "about",
        name: "info_about",
        component: AboutPage,
      },
      {
        path: "contacts",
        name: "info_contacts",
        component: ContactsPage,
      },
      {
        path: "delivery",
        name: "info_delivery",
        component: DeliveryPage,
      },
      {
        path: "privacy",
        name: "info_privacy",
        component: PrivacyPage,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  //закрываем модал с фильтрами
  store.commit('updateStateParam', { title: 'isPopupOpen', value: false });
  //скроллим страницу к началу
  window.scrollTo(0, 0);
  //загружаем данные
  next();
});

export default router;
