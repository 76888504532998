<template>
  <div class="cookie-notification">
    <div class="cookie-notification__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'my-dialog'
}
</script>

<style>
.cookie-notification{
  position: fixed;
  right: 0;
  bottom: 85px;
  z-index: 11;
  background-color: #fff;

}
.cookie-notification__content{
  max-width: 400px;
  min-width: 300px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
</style>